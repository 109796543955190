import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TitleStyle } from "../title/TitleStyle";
import "./style.css";
import emailjs from "emailjs-com";
import { useSnackbar } from "notistack";

export const Devis = ({message, setMessage}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = React.useState({
    nom: "",
    prenom: "",
    tel: "",
    email: "",
    ville: "",
    location: "",
    message: message,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    let html = { ...form };
    emailjs
      .send(
        "service_x0ftk5s",
        "template_nizce0c",
        html,
        "user_jk0izvV6dcrHJmEOU05Ax"
      )
      .then(
        (result) => {
          enqueueSnackbar("demande de devis envoyer avec success", {
            variant: "success",
          });
        },
        (error) => {
          enqueueSnackbar("Opération échouée. Veuillez réessayer.", {
            variant: "error",
          });
        }
      );
  };

  React.useEffect(() => {
    setForm({ ...form, message: message })
  }, [message])

  return (
    <div id="contact" className="devis">
      <TitleStyle title="Demander un devis" />
      <form onSubmit={handleSubmit}>
        <Container>
          <Row className="justify-content-center">
            <Col xs="10">
              <Row>
                <Col xs="12" className="label">
                  <label>Coordonnées :</label>
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <input
                    required
                    type="txt"
                    value={form.nom}
                    onChange={(e) => setForm({ ...form, nom: e.target.value })}
                    placeholder="Nom*"
                  />
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <input
                    type="txt"
                    value={form.prenom}
                    onChange={(e) =>
                      setForm({ ...form, prenom: e.target.value })
                    }
                    placeholder="Prénom*"
                  />
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <input
                    required
                    type="phone"
                    value={form.tel}
                    onChange={(e) => setForm({ ...form, tel: e.target.value })}
                    placeholder="Numéro de téléphone*"
                  />
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <input
                    required
                    type="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    placeholder="Email*"
                  />
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <input
                    required
                    type="txt"
                    value={form.ville}
                    onChange={(e) =>
                      setForm({ ...form, ville: e.target.value })
                    }
                    placeholder="Ville*"
                  />
                </Col>
                <Col xs="12" sm="6" lg="6">
                  <input
                    required
                    type="number"
                    min="1"
                    value={form.location}
                    onChange={(e) =>
                      setForm({ ...form, location: e.target.value })
                    }
                    placeholder="Durée de la location par mois*"
                  />
                  <span className="locationMois">/ Mois</span>
                </Col>
                <Col xs="12" className="label">
                  <label>Message :</label>
                </Col>
                <Col xs="12">
                  <textarea
                    value={form.message}
                    onChange={(e) =>{
                      setForm({ ...form, message: e.target.value });
                    }
                    }
                  ></textarea>
                </Col>
                <button className="item" type="submit">
                  Envoyer
                </button>
              </Row>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};
