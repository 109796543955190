import React from "react";
import "./style.css";
import SearchIcon from "@material-ui/icons/Search";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { withWidth } from "@material-ui/core";
import { TitleStyle } from "../title/TitleStyle";
import CancelIcon from "@material-ui/icons/Cancel";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, animateScroll as scroll } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #18C0F5",
    boxShadow: theme.shadows[5],
    height: "95%",
    width: "80%",
    overflow: "hidden",
    borderRadius: "10px",
    outline: "none",
  },
  padding: {
    padding: theme.spacing(2, 4, 3),
  },
  overflowhidden: {
    position: "relative",
    marginRight: "-12px",
    overflowY: "scroll",
    height: "100%",
  },
  image: {
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    height: "300px",
    maxHeight: "300px",
    borderBottom: "solid lightgray 1px",
    "& img": {
      position: "relative",
      height: "100%",
      left: "50%",
      maxWidth: "90%",
      transform: "translateX(-50%)",
    },
  },
  description: {
    padding: "10px",
  },
  ref: {
    color: "lightgray",
    marginTop: "-50px",
    padding: "10px",
    fontSize: "1.2rem",
  },
  close: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    zIndex: "1000",
    "& :hover": {
      color: "#18C0F5",
    },
    "& svg": {
      fontSize: "2rem",
    },
  },
}));

export const Produit = ({ item, setMessage }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDevis = () => {
    handleClose()
    setMessage(
      `Je souhaite faire la location d'un ${item.nom}`
    );
  }

  return (
    <React.Fragment>
      <Col
        xs={12}
        sm={6}
        md={4}
        lg={4}
        xl={3}
        style={{ height: "100%", position: "relative" }}
        className="liste-article"
      >
        <div
          className="list-article-bloc"
          onClick={handleOpen}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              height: "180px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <img
              className="image-article"
              src={item.image[0]}
              alt={`${item.nom} - ${item.ref} - location matériel médical Maroc`}
            />
            {item.rupture === true ? (
              <img
                className="rupture"
                src={process.env.PUBLIC_URL + "/image/Rupture.png"}
              />
            ) : null}
          </div>
          <p
            className="produit-name-reduce"
            style={{ marginRight: "5px", textAlign: "right" }}
          >
            {item.nom}
          </p>

          <div className="ref">
            <img
              className="rectangle-article"
              src={process.env.PUBLIC_URL + "/assets/article/Rectangle.png"}
            />
            <p
              style={{ marginRight: "5px", color: "#fff" }}
            >{`Ref : ${item.reference}`}</p>
          </div>
          <div className="marque-produit" style={{ textAlign: "right" }}>
            <span style={{ marginRight: "5px", textTransform: "capitalize" }}>
              Marque : {item.marque}
            </span>
          </div>
          {/*
            <div
            className="d-flex flex-column"
            style={{ border: "solid #9F9F9F 1px", borderRadius: "5px", marginTop:"20px" }}
          >
            <div
              className="d-flex"
              style={{ borderBottom: "solid #9F9F9F 1px", height: "30px", position: "relative" }}
            >
              <div style={{ width: "57%", borderRight: "solid #9F9F9F 1px", position:"relative" }}>
                <p
                  className="gray-light"
                  style={{
                    marginRight: "5px",
                    textAlign: "center",
                    margin: "0",
                    color: "#9F9F9F",
                  }}
                >
                  Location / Mois
                </p>
              </div>
              <div style={{width: "57%",fontSize:"0.8em"}}>
                <p className="gray-light" style={{textAlign:"right", marginRight:"15px"}}>
                  {item.prixLocation !== "x"?item.prixLocation.toFixed(2):" - "} <span>Dhs ttc</span>
                </p>
              </div>
            </div>
            <div
              className="d-flex"
              style={{ height: "30px" }}
            >
              <div
                style={{ width: "57%", borderRight: "solid #9F9F9F 1px", position:"relative" }}
              >
              <p
                className="gray-light"
                style={{
                  marginRight: "5px",
                  textAlign: "center",
                  margin: "0",
                  color: "#9F9F9F",
                }}
              >
                Achat
              </p>
              </div>
              <div style={{width: "57%", fontSize:"0.8em"}}>
              <p className="gray-light" style={{textAlign:"right", marginRight:"15px", padding:"0",}}>
                {item.prix.toFixed(2)} <span>Dhs ttc</span>
              </p>
              </div>
              
            </div>
          </div>
              */}
          <div className="filter-hover">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </div>
        </div>
      </Col>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.close} onClick={handleClose}>
              <CancelIcon />
            </div>
            <div className={classes.overflowhidden} style={{ padding: "50px" }}>
              <Container style={{ marginBottom: "50px" }} fluid={true}>
                <Row>
                  <Col xs={12} lg={5}>
                    <Carousel transitionTime="500">
                      {item.image &&
                        item.image.map((image) => (
                          <div
                            className="carousel-image-md"
                            style={{
                              position: "relative",
                              top: "50%",
                              transform: "translateY(-50%)",
                              height: "250px",
                            }}
                          >
                            <img
                              style={{ height: "100%", width: "auto" }}
                              src={image}
                              alt={`${item.nom} - ${item.ref} - location matériel médical Maroc`}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </Col>
                  <Col xs={12} lg={7}>
                    <h4 style={{ marginBottom: "25px" }}>
                      {item.nom &&
                        item.nom.charAt(0).toUpperCase() + item.nom.slice(1)}
                    </h4>
                    <span>{`Ref : ${item.reference && item.reference}`}</span>
                    <h5 className="description-distance">Descriptif : </h5>
                    <p
                      className="article-descripton"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></p>
                    {/*<div className="flexBox f-between column-mobile">
                      <div>
                        <h5 className="description-distance">Achat : </h5>
                        <p>{item.prix.toFixed(2)} DHS TTC</p>
                      </div>
                      <div>
                        <h5 className="description-distance">
                          Location / mois :{" "}
                        </h5>
                        <p>
                          {item.prixLocation !== "x"
                            ? item.prixLocation.toFixed(2)
                            : item.prixLocation}{" "}
                          DHS TTC
                        </p>
                      </div>
                      </div>*/}
                      <div style={{textAlign:"right", marginTop:"25px"}}>
                      <Link to="contact" spy={true} smooth={true}>
                        <Button onClick={handleDevis}>Demander un devis</Button>
                      </Link>
                      </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};
