export const data = [
  {
    id: 0,
    nom: "Lit électrique life 3",
    reference: "K-A539-1",
    image: [
      `${process.env.PUBLIC_URL + "/produits/4-1.jpg"}`,
      `${process.env.PUBLIC_URL + "/produits/4-2.jpg"}`,
    ],
    description:
      "structure en epoxytête et pieds de lit en abs amoviblespaire de ridelles en aluminium rabattables ; hauteur : 35cm01 télécommandepotence télescopique à 2 crochets2 crochets pour poches à urine4 roues en abs silencieuses de 12.5 cm de diamètre, dont 2 à freins moteur électrique linkan3 fonctions :- relève buste : 85 ° ± 5 °- relève jambe : 45 ° ± 5 °- réglage de la hauteur: 450-700mm",
    prix: 10570,
    prixLocation: 1720,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 1,
    nom: "Lit mécanique serima 3",
    reference: "KA-0339",
    image: [
      `${process.env.PUBLIC_URL + "/produits/1-1.jpg"}`,
      `${process.env.PUBLIC_URL + "/produits/1-2.jpg"}`,
    ],
    description:
      "lit hopital mecanique a 3 fonctionstête et pied de lit en plastique abs amovibles et sommier en epoxy4 roues en abs silencieuses, dont 2 à freins2 ridelles en aluminium rabattables1 potence en inox amovible à 2 branches1 crochet pour poche à urine3 fonctions :- relève buste réglable de 0 à 80°par manivelle- soulève jambe réglable de 0 à 40° par manivelle- réglable en hauteur de 450 à 700 mm par manivelle",
    prix: 8154,
    prixLocation: 1325,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 13,
    nom: "Lit mécanique serima 1",
    reference: "KA-318A",
    image: [
      `${process.env.PUBLIC_URL + "/produits/15-1.jpg"}`,
      `${process.env.PUBLIC_URL + "/produits/15-2.jpg"}`,
    ],
    description:
      "lit hopital mecanique a 1 fonctiontête et pied de lit en abs amovibles et sommier en epoxyroues en abs (dont 2 à freins); d=12.5 cm (4 pcs)ridelles en aluminium rabattables de hauteur: 32 cm (2 pcs)potence amovible à 2 branches (1 pc)crochet pour poche à urine de drainage (1 pc)1 fonction :- relève buste réglable de 0 à 80° par manivelle",
    prix: 5835,
    prixLocation: 950,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 2,
    nom: "Matelas en mousse classe 1 pharma plots - 3 parties",
    reference: "04.5036",
    image: [`${process.env.PUBLIC_URL + "/produits/9-1.jpg"}`],
    description:
      "le matelas pharma plots – 3 parties est conçu en mousse moulée haute résilience en troispartiesce matelas d’aide à la prévention des escarres est destiné à des patients présentant des risques faiblesà moyen d’escarres, alités de 10 à 15 heures par 24 heures, qui se mobilisent seuls avec difficulté, sanstroubles neurologiques importants, sans artériopathie, état général bon à moyen.le matelas pharma plots est composé de 3 éléments en mousse polyuréthane haute résilience 37kg/m3 découpée en forme de gaufrier ; il permet une modulation des points d’appui et une améliorationde la ventilation. sa configuration de base permet une meilleure égalisation des points de pression.ce matelas peut être utilisé comme support de lit d’aide au traitement de l’escarre ou en post-chirurgie d’escarre : - chez des patients ayant une ou plusieurs escarres de stade 1 et/ou 2 hors zone d’appui,  ou avec possibilité d’exclusion d’appui, patient levé ou non dans la journée. - chez des patients avec une escarre de stade 3 ou 4 hors zone d’appui ou avec possibilité d’exclusiond’appui - avec un système de décharge localisée - chez des patients avec une escarre de stade 1 ou 2 en zone d’appui, patient levé dans la journée avecun système de positionnement et intervention d’auxiliaires médicaux 3 fois par jour pour vérification del’installation et réalisation de retournement.",
    prix: 1899,
    prixLocation: 310,
    marque: "pharmaouest",
    rupture:false,
  },
  {
    id: 3,
    nom: "Matelas à air + compresseur 220v 'yhmed'",
    reference: "HCAC103",
    image: [
      `${process.env.PUBLIC_URL + "/produits/8-1.jpg"}`,
      `${process.env.PUBLIC_URL + "/produits/8-2.jpg"}`,
    ],
    description:
      "matelas à air en pvc, gonflable· matelas dynamique, au mode alternatif· compresseur silencieux : 220v· dimensions: 200 x 90 cm avec rabats de 50cm de chaque côtéatouts :- compact et léger- facile à entretenir",
    prix: 580.0,
    prixLocation: "x",
    marque: "yhmed",
    rupture:false,
  },
  {
    id: 4,
    nom: "Table à manger 1 plateau en bois à vis",
    reference: "HY2011",
    image: [`${process.env.PUBLIC_URL + "/produits/7-1.jpg"}`],
    description:
      "1 plateau lamifié couleur boissupport epoxy4 roulettes à freinsréglable en hauteur à vis",
    prix: 990,
    prixLocation: 170,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 5,
    nom: "Table à manger 1 plateau en abs à verin à gaz",
    reference: "SKH46-2",
    image: [`${process.env.PUBLIC_URL + "/produits/6-1.jpg"}`],
    description:
      "un plateau en abs, couleur bleu cielcolonne en epoxy avec cache en plastique blanc4 roulettes, dont 2 avec freinsréglable en hauteur par vérin à gaz",
    prix: 2300.0,
    prixLocation: 380,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 6,
    nom: "Table de chevet en abs",
    reference: "SKS002",
    image: [
      `${process.env.PUBLIC_URL + "/produits/5-1.jpg"}`,
      `${process.env.PUBLIC_URL + "/produits/5-2.jpg"}`,
    ],
    description:
      "structure en epoxytête et pieds de lit en abs amoviblespaire de ridelles en aluminium rabattables ; hauteur : 35cm01 télécommandepotence télescopique à 2 crochets2 crochets pour poches à urine4 roues en abs silencieuses de 12.5 cm de diamètre, dont 2 à freins moteur électrique linkan3 fonctions :- relève buste : 85 ° ± 5 °- relève jambe : 45 ° ± 5 °- réglage de la hauteur: 450-700mm",
    prix: 1900.0,
    prixLocation: 800.0,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 7,
    nom: "Concentrateur d'oxygène avec nébuliseur - 10 l/mn série luna",
    reference: "7F--10W",
    image: [`${process.env.PUBLIC_URL + "/produits/12-1.jpg"}`],
    description:
      "débit d'oxygène : 0.5 ~ 10 l/minconcentration o² : 95.5% ~ 87%pression o² : 40 ~ 70 kpapuissance d’entrée : 850 vaalimentation : ac 220v ± 22v, 50 ± 1 hzpoids net : 34.5 kgniveau sonore: < 60 db(a)",
    prix: 18000.0,
    prixLocation: 2925,
    marque: "yuwell",
    rupture:false,
  },
  {
    id: 8,
    nom: "Concentrateur d'oxygène avec nébuliseur - 3l/min série captiva",
    reference: "8F--3AW",
    image: [`${process.env.PUBLIC_URL + "/produits/11-1.jpg"}`],
    description:
      "débit d'oxygène : 0.5 ~ 3 l/minconcentration o² : 93% ± 3 %pression o² : 20 ~ 50 kpapuissance d’entrée : 230 vaalimentation : ac 220v ± 22v, 50 ± 1 hzpoids net : 11.8 kgniveau sonore: face avant 42db(a), toute l’unité 46db(a)",
    prix: 8000,
    prixLocation: 1300,
    marque: "yuwell",
    rupture:false,
  },
  {
    id: 9,
    nom: "Potence à sérum inox 5 pieds en plastique noir",
    reference: "RT-4-5P",
    image: [`${process.env.PUBLIC_URL + "/produits/2-1.jpg"}`],
    description:
      "Tige en chromé réglable en hauteur de 1300 à 2200mm Pieds à 5 branches en plastique noir sur roulettes 4 crochets en plastique",
    prix: 740.0,
    prixLocation: 120,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 10,
    nom: "Paravent inox 2 volets",
    reference: "SM5192S",
    image: [`${process.env.PUBLIC_URL + "/produits/3-1.jpg"}`],
    description:
      "châssis en chromé à 2 panneaux de 60 cm de largeurroulant - pliabletoile lavable blanchepoids : 4kg",
    prix: 1235.0,
    prixLocation: 200,
    marque: "hospitalia",
    rupture:false,
  },
  {
    id: 11,
    nom: "fauteuil roulant adulte basic chrôme",
    reference: "HY9100-46",
    image: [`${process.env.PUBLIC_URL + "/produits/13-1.jpg"}`],
    description:
      "reposes pieds fixes• armature chromée adulte• dossier avec assise en simili cuir• assise 46 x 46cm• freins arrêt sur roues arrières• reposes pieds fixes et pliables en aluminium• pneus tubeless• roues : arrière : 58 cm / devant : 20 cm• poids : 15.6 kg",
    prix: 1450.0,
    prixLocation: 235,
    marque: "caremax",
    rupture:false,
  },
  {
    id: 12,
    nom: "Fauteuil roulant électrique imc - adulte",
    reference: "123GC43",
    image: [`${process.env.PUBLIC_URL + "/produits/14-1.jpg"}`],
    description:
      "infirmite motrice cerebralefauteuil roulant électrique pour adultearmature en epoxy : grisedimensions ext. : 102 x 67 x 113cmcapacité max.: 110kgaccoudoirs détachablesrepose pieds en plastique démontables et réglables en extensionrepose nuque réglable & orientable1 sangle de maintiendossier inclinable par système hydraulique sur un cotéassise et dossier en toile noire rembourréeassise : 43x43 cmmodes : manuel et automatiquevitesse réglable : 0 à 6 km/hvoltage moteur 24 vdc x 2puissance : 200 wbatterie : 50a/230vfreinage : automatiquedistance : 20 km / autonomie : 5 hport charge : ac 220v (2 batteries)pneus arrière tubeless diam. 33cmpneus devant tubeless diam. 20cm2 feux devant oranges à interrupteur avec 2 ampoulesmanette modulable fixée sur accoudoir à système rétractablepoids: 62.8 kg",
    prix: 30000.0,
    prixLocation: 4875,
    marque: "caremax",
    rupture:true,
  },
  {
    id: 14,
    nom: "rolateur en aluminium à 4 roues",
    reference: "HY8410L",
    image: [`${process.env.PUBLIC_URL + "/produits/16-1.jpg"}`],
    description:
      "pliable - démontable• assise : 46cm en cuir noir• ossature en aluminium en couleur bordeaux• a de 2 freins à main• panier + sac• hauteur de l’assise au sol = 55 cm• hauteur des poignets au sol de 81 - 91 cm (réglable en hauteur)",
    prix: 1460.0,
    prixLocation: 240,
    marque: "caremax",
    rupture:false,
  },
  {
    id: 15,
    nom: "Oxymètre de doigt adulte gris",
    reference: "CMS50-D1",
    image: [`${process.env.PUBLIC_URL + "/produits/17-1.jpg"}`],
    description:
      "mesure de la saturation d’oxygène dans le sang – spo2mesure de la fréquence du pouls pr à travers le capteur de doigtgraphique à barres et en courbesfaible consommation d'énergiefacile d’utilisationalarme de basse tension de la batteriegraphique à barres et en courbesmode d’affichage et luminosité de l’écran réglablestemps de marche : 20h continu",
    prix: 500.0,
    prixLocation: "x",
    marque: "contec",
    rupture:false,
  },
];
