import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import "lazysizes";
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import Grid from "@material-ui/core/Grid";
import { Container, Hidden } from "@material-ui/core";
import { Phone, Email } from "@material-ui/icons";
import { Link, animateScroll as scroll } from "react-scroll";

export const Navigation = () => {
  return (
    <Navbar
      className="navigation"
      sticky="top"
      collapseOnSelect
      expand="lg"
      variant="light"
      style={{ height: "65px", padding: "5px", background: "#2EB0FA" }}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Container className="container-navigation">
          <Nav
            className="flexBox justify-content-center w100"
            style={{ right: "0" }}
          >
            <Nav.Link>
              <Link to="accueil" spy={true} smooth={true}>
                Accueil
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="presentation" spy={true} smooth={true}>
                Qui sommes nous ?
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="pack" spy={true} smooth={true}>
                Nos packs
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link to="produit" spy={true} smooth={true}>
                Nos produits
              </Link>
            </Nav.Link>
            <Nav.Link className="no-border">
              <Link to="contact" spy={true} smooth={true}>
                Contactez nous
              </Link>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar.Collapse>
    </Navbar>
  );
};
