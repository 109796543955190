import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, animateScroll as scroll } from "react-scroll";

export const Packs = ({ setMessage }) => {
  const basicClick = () => {
    setMessage(
      "Je souhaite faire une location du pack Basique contenant : \n - Lit mécanique 3 positions \n - Matelas anti escarre"
    );
  };

  const standardClick = () => {
    setMessage(
      "Je souhaite faire une location du pack Standard contenant : \n - Lit éléctrique 3 positions \n - Matelas anti escarre \n"
    );
  };

  const completClick = () => {
    setMessage(
      "Je souhaite faire une location du pack Complet contenant : \n - Lit éléctrique 3 positions \n - Matelas anti escarre \n - Table à manger"
    );
  };
  const premiumClick = () => {
    setMessage(
      "Je souhaite faire une location du pack Premium contenant : \n - Lit éléctrique 3 positions \n - Matelas anti escarre \n - Table à manger \n - Concentrateur 5 litres"
    );
  };

  return (
    <div className="bloc" id="pack">
      <div id="pack-title">
        <h2>NOS PACKS</h2>
        <h3>LOCATIONS</h3>
      </div>
      <Container>
        <Row className="justify-content-center">
          <Col xs={11}>
            <Row className="justify-content-center">
              <Col className="no-pad" xs={10} sm={10} md={3}>
                <div className="pack pack-basic">
                  <div
                    className="pack-title"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg/basic.png)`,
                    }}
                  >
                    <h5>BASIQUE</h5>
                  </div>
                  {<div className="pack-prix">
                    <div>
                      <p>Location</p>
                      <span>par mois</span>
                    </div>
                  </div>}
                  <div className="pack-info">
                    <ul>
                      <li>Lit mécanique 3 positions</li>
                      <li>Matelas anti escarre</li>
                    </ul>
                  </div>
                  <div className="pack-button">
                    <Link to="contact" spy={true} smooth={true}>
                      <button onClick={basicClick}>Sélectionner</button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col className="no-pad" xs={10} sm={10} md={3}>
                <div className="pack pack-standard">
                  <div
                    className="pack-title"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg/standard.png)`,
                    }}
                  >
                    <h5>STANDARD</h5>
                  </div>
                  <div className="pack-prix">
                    <div>
                      <p>Location</p>
                      <span>par mois</span>
                    </div>
                  </div>
                  <div className="pack-info">
                    <ul>
                      <li>Lit éléctrique 3 positions</li>
                      <li>Matelas anti escarre</li>
                    </ul>
                  </div>
                  <div className="pack-button">
                    <Link to="contact" spy={true} smooth={true}>
                      <button onClick={standardClick}>Sélectionner</button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col className="no-pad" xs={10} sm={10} md={3}>
                <div className="pack pack-complet">
                  <div
                    className="pack-title"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg/complet.png)`,
                    }}
                  >
                    <h5>COMPLET</h5>
                  </div>
                  <div className="pack-prix">
                    <div>
                      <p>Location</p>
                      <span>par mois</span>
                    </div>
                  </div>
                  <div className="pack-info">
                    <ul>
                      <li>Lit éléctrique 3 positions</li>
                      <li>Matelas anti escarre</li>
                      <li>Table à manger</li>
                    </ul>
                  </div>
                  <div className="pack-button">
                    <Link to="contact" spy={true} smooth={true}>
                      <button onClick={completClick}>Sélectionner</button>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col className="no-pad" xs={10} sm={10} md={3}>
              <div className="pack pack-premium">
                <div
                  className="pack-title"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/bg/premium.png)`,
                  }}
                >
                  <h5>Premium</h5>
                </div>
                <div className="pack-prix">
                  <div>
                    <p>Location</p>
                    <span>par mois</span>
                  </div>
                </div>
                <div className="pack-info">
                  <ul>
                    <li>Lit éléctrique 3 positions</li>
                    <li>Matelas anti escarre</li>
                    <li>Table à manger</li>
                    <li>Concentrateur 5 litres</li>
                  </ul>
                </div>
                <div className="pack-button">
                  <Link to="contact" spy={true} smooth={true}>
                    <button onClick={premiumClick}>Sélectionner</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
