import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Mediloc } from './view/Mediloc'
import { MetaTags } from './components/MetaTags';
import React from 'react';
function App() {

  React.useEffect(() => {
    if (window.location.hostname == "mediloc-1d5a4.web.app" || 
        window.location.hostname == "mediloc-1d5a4.firebaseapp.com") {
          window.location.href = 'https://mediloc.ma' 
    }
  }, [])
  return (
    <div className="App">
    <MetaTags
      meta={
          {
              title:"Mediloc location matériel médical maroc", 
              description:"Mediloc , spécialiste marocain des équipements Medical : paravent, oxygénateur, concentrateur d'oxygène, lit médical, table de chevet, table à manger, porte serum, alaise médicale livraison partout au Maroc",
              keywords:"location, location medical, location lit médical, locations lit médicales location paravent, location oxygénateur, location de table de chevet, location porte serum, location alaise medicales, locations médicals rabat, locations médicals casablanca, locations médicals tanger, locations médicals meknes, locations médicals marrakech, locations médicals mohammedia, locations médicals bouznika, locations médicals témara, locations médicals salé, locations médicals agadir, locations médicals errachidia, locations médicals tétouan, locations médicals safi, locations médicals el jadida, locations médicals nador, locations médicals taza, locations médicals kénitra, locations médicals essaouira, locations médicals oujda, locations médicals chefchaouen, locations médicals ouarzazate, locations médicals ouazzane, locations médicals tazza, locations médicals asilah, locations médicals béni mellal, locations médicals taroudant, locations médicals al hocceima, locations médicals larache, locations médicals ifrane, locations médicals berrchid, locations médicals azemmour, locations médicals zagora, locations médicals khémisset, locations médicals khouribga, locations médicals sidi kacem, locations médicals merzouga, locations médicals tiznit, locations médicals benslimane,  client, Maroc, Casablanca",
              url:"https://mediloc.ma",
              image:`http://mediloc.ma/logo/logo.svg`,
          }
      } />
      <Mediloc />
    </div>
  );
}

export default App;
