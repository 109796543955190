import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Link, animateScroll as scroll } from "react-scroll";

export const SliderHeader = () => {
    const [index, setIndex] = React.useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Container className="carousel-Accueil" fluid={true} style={{ paddingTop: "15px", position: "relative" }}>
            <Row style={{ position: "relative" }}>
                <Col lg={8}>
                    <Carousel className="carousel-Accueil" activeIndex={index} onSelect={handleSelect} style={{ height: "100%" }}>
                        <Carousel.Item interval={2500} style={{ position: "relative", height: "100%" }}>
                            <Carousel.Caption style={{ position: "absolute", top: "0", height: "100%" }}>
                                <Container style={{ position: "relative", height: "100%" }}>
                                    {/*<Row className="justify-content-end" style={{ position: "relative", height: "100%" }}>
                                        <Col xs={6} style={{ position: "relative", height: "100%" }} className="flexBox flexBox-col f-center">
                                            <div className="header-content">
                                                <h1>Yatesmed</h1>
                                                <h3>Votre partenaire <br />santé</h3>
                                                <button className="button" href="#">Depuis 2015</button>
                                            </div>
                                        </Col>
    </Row>*/}
                                </Container>
                            </Carousel.Caption>
                            <img className="header-carousel-bgImage" src={process.env.PUBLIC_URL + "/assets/header/Slider1.jpg"} alt="yatesmed" />
                        </Carousel.Item>
                        <Carousel.Item interval={2500} style={{ position: "relative", height: "100%" }}>
                            <Carousel.Caption style={{ position: "absolute", top: "0", height: "100%" }}>
                                <Container style={{ position: "relative", height: "100%" }}>
                                    {/*<Row className="justify-content-end" style={{ position: "relative", height: "100%" }}>
                                        <Col xs={6} style={{ position: "relative", height: "100%" }} className="flexBox flexBox-col f-center">
                                            <div className="header-content">
                                                <h1>Yatesmed</h1>
                                                <h3>Votre partenaire <br />santé</h3>
                                                <button className="button" href="#">Depuis 2015</button>
                                            </div>
                                        </Col>
    </Row>*/}
                                </Container>
                            </Carousel.Caption>
                            <img className="header-carousel-bgImage" src={process.env.PUBLIC_URL + "/assets/header/Slider2.jpg"} alt="yatesmed" />
                        </Carousel.Item>
                        <Carousel.Item interval={2500} style={{ position: "relative", height: "100%" }}>
                            <Carousel.Caption style={{ position: "absolute", top: "0", height: "100%" }}>
                                <Container style={{ position: "relative", height: "100%" }}>
                                    {/*<Row className="justify-content-end" style={{ position: "relative", height: "100%" }}>
                                        <Col xs={6} style={{ position: "relative", height: "100%" }} className="flexBox flexBox-col f-center">
                                            <div className="header-content">
                                                <h1>Yatesmed</h1>
                                                <h3>Votre partenaire <br />santé</h3>
                                                <button className="button" href="#">Depuis 2015</button>
                                            </div>
                                        </Col>
    </Row>*/}
                                </Container>
                            </Carousel.Caption>
                            <img className="header-carousel-bgImage" src={process.env.PUBLIC_URL + "/assets/header/Slider3.jpg"} alt="yatesmed" />
                        </Carousel.Item>

                    </Carousel>
                </Col>
                <Col lg={4} className="md-carousel-small">
                <a href="">
                <Link to="produit" spy={true} smooth={true}>
                    <div style={{ height: "48%", marginBottom: "20px" }}>
                        <h1 style={{ position: "absolute", opacity: "0" }}>Signalétique rabat maroc</h1>
                        <img style={{ height: "100%" }} src={process.env.PUBLIC_URL + "/assets/header/petit1.jpg"} alt="yatesMed" />
                    </div>
                </Link>
                </a>
                <a target="_blank" href="https://yatesmed.ma">
                    <div style={{ height: "48%"}}>
                        <h1 style={{ position: "absolute", opacity: "0" }}>EPI produits personalisable</h1>
                        <img style={{ height: "100%" }} src={process.env.PUBLIC_URL + "/assets/header/petit2.jpg"} alt="yatesMed" />
                    </div>
                </a>
                </Col>
            </Row>
        </Container>

    )
}
