import React from 'react'
import Button from '@material-ui/core/Button';
import { Col, Container, Row } from 'react-bootstrap'
import "./style.css"
import { TitleStyle } from '../title/TitleStyle';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Hidden } from '@material-ui/core';
export const Contact = () => {
    const [form, setForm] = React.useState({
        nomRc:"",
        email:"",
        message:""
    })
    return (
        <div style={{marginBottom:"100px"}}>
        <TitleStyle color title="CONTACTEZ NOUS" />
            <Container>
                <Row className="justify-content-center row-contact">
                {/*
                    <Col className="col-contact" xs={10} sm={5} md={4} lg={4}>
                        <div className="contact contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.0743942134986!2d-6.9199887498779145!3d33.91348483238338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7133c245bddc7%3A0xb1b28da1ff293c86!2sYates%20Equipement%20-%20Chaussure%20de%20s%C3%A9curit%C3%A9%20-%20EPI!5e0!3m2!1sfr!2sma!4v1615213143426!5m2!1sfr!2sma" allowfullscreen="" loading="lazy" style={{width:"100%", border:0}}></iframe>
                        </div>
                    </Col>
                */}    
                    <Col className="col-contact" xs={10} sm={10} md={8} lg={8}>
                        <div className="contact contact-info">
                        <div>
                        <h3>Contact</h3>
                            <p>Si vous avez une question, utilisez les informations de contact si dessous : </p>
                        </div>
                        <Hidden only={["xs","sm"]}>
                        <div style={{display:"flex"}}>
                            <div className="flexBox" style={{marginRight:"50px"}}>
                                <div className="contact-icon">
                                    <img className="lazyload" data-src={process.env.PUBLIC_URL+'/assets/icon/send2.svg'} alt="email mediloc" />
                                </div>
                                <div>
                                    <span>Email :</span>
                                    <p style={{width:"max-content"}}>info@yatesmed.ma</p>
                                </div>
                            </div>
                            <div className="flexBox">
                                <div className="contact-icon">
                                    <img className="lazyload" data-src={process.env.PUBLIC_URL+'/assets/icon/tel2.svg'} alt="contact" />
                                </div>
                                <div>
                                    <span>Téléphone :</span>
                                    <p style={{padding:"0 !important", margin:"0px"}}>+212 662 746 731</p>
                                    <p style={{padding:"0 !important", margin:"0px"}}>+212 666 859 117</p>
                                    <p>+212 537 413 765</p>
                                </div>
                            </div>
                        </div>
                        </Hidden>
                        <Hidden only={["lg","xl", "md"]}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div className="flexBox" style={{marginRight:"50px"}}>
                                <div className="contact-icon">
                                    <img className="lazyload" data-src={process.env.PUBLIC_URL+'/assets/icon/send2.svg'} alt="email" />
                                </div>
                                <div>
                                    <span>Email :</span>
                                    <p style={{width:"max-content"}}>info@yatesmed.ma</p>
                                </div>
                            </div>
                            <div className="flexBox">
                                <div className="contact-icon">
                                    <img className="lazyload" data-src={process.env.PUBLIC_URL+'/assets/icon/tel2.svg'} alt="contact" />
                                </div>
                                <div>
                                    <span>Téléphone :</span>
                                    <p style={{padding:"0 !important", margin:"0px"}}>+212 662 746 731</p>
                                    <p style={{padding:"0 !important", margin:"0px"}}>+212 666 859 117</p>
                                    <p>+212 537 413 765</p>
                                </div>
                            </div>
                        </div>
                        </Hidden>
                        
                        </div>
                    </Col>
                {/*    
                    <Col className="col-contact" xs={10} sm={5} md={4} lg={4}>
                        <div className="contact contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.47941374201!2d-7.162044784792262!3d33.77411598068254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7a764d69e3021%3A0x27f283cbc628fd25!2sYates%20Equipement%20-%20Chaussure%20de%20s%C3%A9curit%C3%A9%20-%20EPI!5e0!3m2!1sfr!2sma!4v1617449916668!5m2!1sfr!2sma" allowfullscreen="" loading="lazy" style={{width:"100%", border:0}}></iframe>
                        </div>
                    </Col>
                */}
                </Row>
            </Container>
        </div>
        
    )
}
