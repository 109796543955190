import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import "lazysizes";
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import { TitleLine } from "./title/TitleLine";

export const Presentation = () => {
  return (
    <Container fluid={true}>
      <Row className="justify-content-md-center">
        <Col xs={12} lg={11}>
          <Row className="presentation">
            <Col xs={12} lg={6}>
              <div
                className="presentation"
                style={{
                  width: "90%",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <Row style={{ height: "100%" }}>
                  <Col className="presentation-text" style={{ height: "100%" }}>
                    <div className="d-flex" className="logo-client">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/logo-client/1.jpg"
                          }
                        />
                      </div>
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/logo-client/2.jpg"
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex" className="logo-client">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/logo-client/3.jpg"
                          }
                        />
                      </div>
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/logo-client/4.jpg"
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex" className="logo-client">
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/logo-client/5.jpg"
                          }
                        />
                      </div>
                      <div>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/logo-client/6.jpg"
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="display-mobile-none" xs={12} lg={6} style={{ padding: "0px", textAlign: "center" }}>
              <img
                className="lazyload"
                width="100"
                height="100"
                style={{
                  width: "80%",
                  height: "auto",
                  position: "relative",
                  top: "0",
                }}
                data-src={process.env.PUBLIC_URL + "/image/image36.png"}
                alt="presentation yatesmed"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
