import React , { useState } from 'react'
import reactDom from 'react-dom'
import { Contact } from '../components/contact/Contact'
import { Devis } from '../components/devis/Devis'
import { SliderHeader } from '../components/SliderHeader'
import { Presentation } from '../components/Presentation'
import { Navigation } from '../components/Navigation'
import { InfoSection } from '../components/InfoSection'
import { Footer } from '../components/Footer'
import { NosProduit } from '../components/NosProduit'
import { Pilier } from '../components/Pilier'
import { Packs } from '../components/Packs'

export const Mediloc = () => {
    const [message, setMessage] = useState("")

    return (
        <React.Fragment>
            <InfoSection />
            <Navigation /> 
            <SliderHeader /> 
            <Pilier />
            <Presentation />
            <Packs setMessage={setMessage} />
            <NosProduit setMessage={setMessage} />
            <Devis message={message} setMessage={setMessage} />
            <Contact />
            <Footer />
            
        </React.Fragment>
    )
}
