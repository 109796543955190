import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TitleLine } from "./title/TitleLine";
import { Link } from "react-router-dom";
import { data } from "../data/produit";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';
import { TitleStyle } from './title/TitleStyle';
import CancelIcon from '@material-ui/icons/Cancel';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Produit } from './produits/Produit'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position:"relative",
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #18C0F5',
    boxShadow: theme.shadows[5],
    height:"95%",
    width:"80%",
    overflow:"hidden",
    borderRadius:"10px",
    outline: 'none'
  },
  padding:{
      padding: theme.spacing(2, 4, 3),
  },
  overflowhidden:{
      position:"relative",
      marginRight:"-12px",
      overflowY:"scroll",
      height:"100%"
  },
  image: {
      position:"relative",
      left:"50%",
      transform:"translateX(-50%)",
      height:"300px",
      maxHeight:"300px",
      borderBottom:"solid lightgray 1px",
      "& img":{
          position:"relative",
          height:"100%",
          left:"50%",
          maxWidth:'90%',
          transform:"translateX(-50%)"
      }
  },
  description: {
      padding:"10px"
  },
  ref:{
      color:"lightgray",
      marginTop:"-50px",
      padding:"10px",
      fontSize:"1.2rem"
  },
  close:{
      position:"absolute",
      top:"10px",
      right:"10px",
      cursor:"pointer",
      zIndex:"1000",
      "& :hover":{
          color:"#18C0F5"
      },
      "& svg":{
          fontSize:"2rem",
      }
  }
}));

export const NosProduit = ({setMessage}) => {
  const [produits, setProduits] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container fluid id="produit">
      <Row className="justify-content-center">
        <Col xs={11} className="bloc-nos-produit">
          <div style={{ marginTop: "15px", marginLeft: "15px" }}>
            <TitleLine title="Nos produits" />
          </div>

          <Row>
            {data.map((item) => (
              <Produit item={item} setMessage={setMessage} />
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
