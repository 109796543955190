import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { Phone, Email } from '@material-ui/icons';
export const InfoSection = () => {
    return (
        <div id="accueil" className="min-bloc infoSection" style={{ background: "#fff", color: "#6F6F6E" }}>
            <Container fluid={true}>
                <Row className="justify-content-center">
                    <Col lg={2} xs={12} sm={3}>
                        <div className="square" style={{position:"relative"}}>
                            <a href="/" className="logo" href="/" style={{position:"relative"}}>
                            <img className="lazyload" data-src={process.env.PUBLIC_URL +'/logo/logo.svg'} style={{position:"relative",height:"80px"}} alt="yatesmed" />
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} sm={9} style={{justifyContent:"center"}}>
                        <div className="flexBox info-top-section" style={{justifyContent:"flex-end"}}>
                            <div className="flexBox">
                                <Phone />
                                <div className="flexBox" style={{marginLeft:"10px"}}>
                                    <p style={{ whiteSpace: "nowrap" }} className="no-padding">+212 662 746 731</p>
                                    <p style={{ whiteSpace: "nowrap" }} className="no-padding">+212 666 859 117</p>
                                </div>
                            </div>
                            <div className="flexBox">
                                <Email />
                                <p style={{ whiteSpace: "nowrap", marginLeft:"10px" }} className="no-padding">info@yatesmed.ma</p>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
