import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const Pilier = () => {
  return (
    <div style={{ marginTop: "80px", marginBottom: "80px" }} id="presentation">
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={11}>
            <Row className="justify-content-center">
              <Col xs={12} sm={8} md={4} lg={4} className="col-bloc">
                <div className="pilier">
                  <img
                    src={process.env.PUBLIC_URL + "/icon/Présentation.svg"}
                  />
                  <h4>PRÉSENTATION</h4>
                  <p>
                    Mediloc est un site internet marocain de la société
                    Yatesmed. Spécialisé dans la location de matériel médical
                    pour particuliers et professionnels, Mediloc apporte la
                    meilleure solution pour les personnes alitées à la maison ou
                    en sortie d’hôpital. Nos équipes se déplacent chez vous pour
                    installer tout le matériel nécessaire afin d’accueillir le
                    patient à la maison dans les meilleures conditions pour
                    continuer son rétablissement. Pour cela, nous avons conçu
                    des packs de location pour tout besoin.
                  </p>
                </div>
              </Col>

              <Col xs={12} sm={8} md={4} lg={4} className="col-bloc">
                <div className="pilier">
                  <img src={process.env.PUBLIC_URL + "/icon/Objectif.svg"} />
                  <h4>NOTRE OBJECTIF</h4>
                  <p>
                    Apporter une solution immédiate ainsi qu'un confort aux
                    patients qui sortent d'hôpital ayant un besoin imminent en
                    matériel médical à la maison (lit médicalisé, concentrateur d'oxygène
                    ... etc). Notre objectif est de servir au maximum les
                    personnes en détresses, d'alléger leurs charges financières et
                    être un agent actif dans le processus de guérison en
                    apportant un meilleur confort aux patients et un réconfort
                    aux familles.
                  </p>
                </div>
              </Col>
              <Col xs={12} sm={8} md={4} lg={4} className="col-bloc">
                <div className="pilier">
                  <img src={process.env.PUBLIC_URL + "/icon/Vision.svg"} />
                  <h4>NOTRE VISION</h4>
                  <p>
                    Être non seulement un prestataire de location de matériel
                    médical, mais aussi un soutien aux familles des patients.
                    Nous sommes conscients pour l’avoir vécu du poids émotionnel
                    et financier que cela représente d’avoir un proche alité,
                    raison pour laquelle la vision de Mediloc est centrée sur
                    l’accompagnement des familles des patients tout au long du
                    processus et d’alléger leurs charges financières en
                    proposant des contrats de location à court, moyen et long
                    terme.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
