import React from 'react'

export const TitleStyle = ({color, title}) => {
    return (
        <div style={{textAlign:"left", display:"flex", marginTop:"50px", marginBottom:"50px"}}>
            <div style={{height:"40px", width:"50px", background:`${color?"#18C0F5":"#fff"}`, marginRight:"15px"}}></div>
            <h2 style={{textTransform:"uppercase", color:`${color?"#18C0F5":"#fff"}`, fontWeight:"bold"}}>{title}</h2>
        </div>
    )
}
